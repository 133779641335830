import React, { useState, useEffect } from 'react';
import InfoSlide from './InfoSlide';

const FacebookCounter = () => {
  const [followersCount, setFollowersCount] = useState(0);
  const [poll, setPoll] = useState(false); // Use state for poll
  const [previousFollowerCount, setPreviousFollowerCount] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [fudgeCount, setFudgeCount] = useState(false); // Use state for fudge count
  const [newFollowerAdded, setNewFollowerAdded] = useState(false); // Use state for new follower added
  const [showInfoSlide, setShowInfoSlide] = useState(false); // Use state for hide input
  const [isOffline, setIsOffline] = useState(false); // Use state for offline
  const [pollCount, setPollCount] = useState(0); // Use state for poll count
  const ACCESS_TOKEN = process.env.REACT_APP_FACEBOOK_ACCESS_TOKEN;
  const pausePolling = 60000; // 1mins
  const [isPlayingAnimation, setIsPlayingAnimation] = useState(false); // Use state for playing animation

  const intervalSetting = 25000; // 25s
  const [inputValue, setInputValue] = useState(''); // State to store the input value

  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update the state when the input changes
  };

  useEffect(() => {
    if(isPlayingAnimation) return;
    console.log('Polling Facebook Counter started at: ', new Date());

      const interval = setInterval(() => {
        getData().then((data) => {
          if(data !== undefined && data.followers_count !== undefined)
            {
              setPollCount(prevCount => prevCount + 1);
              setFollowersCount(data.followers_count);
              setIsOffline(false);
              //console log the count and the date and time stamp
              console.log('Followers count =', data.followers_count, 'at', new Date());

            } else {
              console.log('No data returned from API');
              setIsOffline(true);
              ShowVideos();
             
            }
          
        });
      }, intervalSetting);

      // Clear interval on component unmount
      return () => clearInterval(interval);
    // }
  }, [poll]);

  useEffect(() => {
    console.log('Poll count =', pollCount);
    //create random numer between 1 and 10
    const random = 2;// Math.floor(Math.random() * 2) + 1;
    console.log('Random number =', random);

    


    if (pollCount === random) {
      setShowInfoSlide(true);
      setIsPlayingAnimation(true);
      console.log("playing the info slide component");
      //wait 30s then set the showInfoSlide to false
      
      if (isPlayingAnimation) return;
      setTimeout(() => {
        setShowInfoSlide(false);
        setIsPlayingAnimation(false);
        console.log("hiding the info slide component");
      }, 10000);

    }
  }, [pollCount]);




  const getData = async () => {
  
    try {
     
      const response = await fetch(
        `https://graph.facebook.com/theindiethieves?fields=id,followers_count&access_token=${ACCESS_TOKEN}`
      );
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const ShowVideos = () => {
    console.log('Show videos ');
  };



  useEffect(() => {
    //this only runs when the followersCount changes
    console.log('New follower count =', followersCount);
    console.log('previous count =', previousFollowerCount);
    if (followersCount > previousFollowerCount) {
      //only animate if counter goes up
      setPreviousFollowerCount(followersCount); // for next time's comparison
      console.log('previous count =', previousFollowerCount);
      setAnimate(true); //trigger a css animation by adding the animate class to flash/grow recolour the counter

      setTimeout(() => {
        setAnimate(false); //when animation is finished- remove the animation class ready for next time
        console.log('animate = ', animate);
      }, 3000);
    }
  }, [followersCount]);

  return (
    <div className='no-scrollbars'  style={{
      margin: 0,
      padding: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      {/* If no signal */}
      {isOffline && <span className='offlineIndicator'><svg fill="#c00" height="80px" width="80px" id="wifi"  
	 viewBox="0 0 365.892 365.892">
<g>
	<circle cx="182.945" cy="286.681" r="41.494"/>
	<path d="M182.946,176.029c-35.658,0-69.337,17.345-90.09,46.398c-5.921,8.288-4.001,19.806,4.286,25.726
		c3.249,2.321,6.994,3.438,10.704,3.438c5.754,0,11.423-2.686,15.021-7.724c13.846-19.383,36.305-30.954,60.078-30.954
		c23.775,0,46.233,11.571,60.077,30.953c5.919,8.286,17.437,10.209,25.726,4.288c8.288-5.92,10.208-17.438,4.288-25.726
		C252.285,193.373,218.606,176.029,182.946,176.029z"/>
	<path d="M182.946,106.873c-50.938,0-99.694,21.749-133.77,59.67c-6.807,7.576-6.185,19.236,1.392,26.044
		c3.523,3.166,7.929,4.725,12.32,4.725c5.051-0.001,10.082-2.063,13.723-6.116c27.091-30.148,65.849-47.439,106.336-47.439
		s79.246,17.291,106.338,47.438c6.808,7.576,18.468,8.198,26.043,1.391c7.576-6.808,8.198-18.468,1.391-26.043
		C282.641,128.621,233.883,106.873,182.946,106.873z"/>
	<path d="M360.611,112.293c-47.209-48.092-110.305-74.577-177.665-74.577c-67.357,0-130.453,26.485-177.664,74.579
		c-7.135,7.269-7.027,18.944,0.241,26.079c3.59,3.524,8.255,5.282,12.918,5.281c4.776,0,9.551-1.845,13.161-5.522
		c40.22-40.971,93.968-63.534,151.344-63.534c57.379,0,111.127,22.563,151.343,63.532c7.136,7.269,18.812,7.376,26.08,0.242
		C367.637,131.238,367.745,119.562,360.611,112.293z"/>
</g>
</svg>
        </span>
      }
      {/* if we have signal and are not showing any animations/info slides */}
      {!showInfoSlide && 
        <div className='centered'>
        <h1 className={`${animate ? 'animate' : ''}`}>{followersCount} </h1>
      </div>}
        {/* If we are showing showing the info slide */}
        {showInfoSlide && <InfoSlide />}

      
    </div>
  );
};

export default FacebookCounter;
