import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';
import logo from './../images/IndieTheivesCirlceLogoLarge.jpg';

const Header = () => {
  return (
    <div className='fullWidthGridLayout'>
      <div className='gridItem_1'>
        <SocialIcon
          url='https://www.facebook.com/theindiethieves'
          style={{ height: 75, width: 75 }}
          bgColor='transparent'
        />
      </div>
      <div className='gridItem_2'>
        <img
          src={logo}
          alt='Indie Thieves Logo'
          className='IndieTheievesHerologo'
        />{' '}
      </div>
      <div className='gridItem_3'>
        <a href='#gigs' className='headerGridButton'>
          <h1>GIGS</h1>
          <p>Find out when and where we're playing!</p>
        </a>
      </div>
      <div className='gridItem_4'>
        <SocialIcon
          url='https://www.instagram.com/theindiethieves'
          style={{ height: 75, width: 75 }}
          bgColor='transparent'
        />
      </div>
      <div className='gridItem_5'>
        <a href='#videos' className='headerGridButton'>
          <h1>VIDEOS</h1>
          <p>Live performances, promos and showreels</p>
        </a>
      </div>
      <div className='gridItem_6'>
        <SocialIcon
          url='https://www.youtube.com/@theindiethieves123'
          style={{ height: 75, width: 75 }}
          bgColor='transparent'
        />
      </div>
      <div className='gridItem_7'>
        <Link href='#bios' className='headerGridButton'>
          <h1>ABOUT</h1>
          <p>Just who are The Indie Thieves?</p>
        </Link>
      </div>
      <div className='gridItem_8'>
        <SocialIcon
          href='mailto:contactus@theindiethieves.co.uk'
          network='email'
          style={{ height: 75, width: 75 }}
          bgColor='transparent'
        />
      </div>

      <div className='gridItem_9'>
        <Link to='/Media' className='headerGridButton'>
          <h1>MEDIA</h1>
          <p>Printable posters/images for advertising </p>
        </Link>
      </div>
    </div>

    // <div className='newHeader'>
    //   <div className='mainArea'>
    //     {/* <FancyHeader /> */}
    //     <img
    //       src= {logo}
    //       alt='Indie Thieves Logo'
    //       className='IndieTheievesHerologo'/>
    //   </div>
    //   <div className='buttonArea1 buttonArea'>
    //     <a href='#gigs' id='gigsButton'>
    //       <h1>GIGS</h1>
    //       <p>Find out when and where we're playing!</p>
    //     </a>
    //   </div>
    //   <div className='buttonArea2 buttonArea'>
    //     <a href='#videos' id='videosButton'>
    //       <h1>VIDEOS</h1>
    //       <p>See our live performances, promotional stuff and showreels</p>
    //     </a>
    //   </div>
    //   <div className='buttonArea3 buttonArea'>
    //     <a href='#bios' id='aboutButton'>
    //       <h1>ABOUT</h1>
    //       <p>Just who are The Indie Thieves?</p>
    //     </a>
    //   </div>
    //   <div className='buttonArea4 buttonArea'>
    //     <Link to='/Media' id='mediaButton'>
    //       <h1>MEDIA</h1>
    //       <p>Printable posters and images for social media are here</p>
    //     </Link>
    //   </div>
    // </div>
  );
};

export default Header;
